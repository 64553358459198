jQuery( document ).ready(function() {
     
    
    ////////////////End///////////////
    
        var arr = [];
        var xhr;
        var pro = "";
        //jQuery('.frmExternalTrace').each(function() {
        jQuery('.trace-input').on('keyup',function() {
            var key = jQuery(this).val().replace(" ","");
            key = key.charAt(0);
            if (key == 'O' || key == 'o') {
                jQuery(this).addClass('error');
                // jQuery( '<div>Polaris tracking numbers do not include "O" please type zero instead.</div>' ).dialog({
                //     modal: true,
                //     title: "PLEASE NOTE",
                //     buttons: [
                //         {
    
                //             text: "Ok",
                //             icons: {
                //                 primary: "ui-icon-circle-check"
                //             },
                //             click: function() {
                //                 jQuery( this ).dialog( "close" );
                //             }
                //         }
                //     ]
                // }).prev(".ui-dialog-titlebar").css( "background","#e6c933");
                jQuery('.trace-wrong-number-modal').modal('show');
              
            }
            else {
                jQuery(this).removeClass('error');
            }
        });
        jQuery('.trace-input-mob').on('keyup',function() {
            var key = jQuery(this).val().replace(" ","");
            key = key.charAt(0);
            if (key == 'O' || key == 'o') {
                jQuery(this).addClass('error');
                // jQuery( '<div>Polaris tracking numbers do not include "O" please type zero instead.</div>' ).dialog({
                //     modal: true,
                //     title: "PLEASE NOTE",
                //     buttons: [
                //         {
    
                //             text: "Ok",
                //             icons: {
                //                 primary: "ui-icon-circle-check"
                //             },
                //             click: function() {
                //                 jQuery( this ).dialog( "close" );
                //             }
                //         }
                //     ]
                // }).prev(".ui-dialog-titlebar").css( "background","#e6c933");
                jQuery('.trace-wrong-number-modal').modal('show');
              
            }
            else {
                jQuery(this).removeClass('error');
            }
        });
    
    
        jQuery('.frmExternalTrace').submit(function(e) {
            return false;
        });
    
    
        jQuery(".trace-input").keyup(function(e){
            var code = e.which; // recommended to use e.which, it's normalized across browsers
            if(code==13)e.preventDefault();
            if(code==32||code==13||code==188||code==186){
                var sel_po = jQuery(this).parent();
                track(sel_po,"1");
            }
        });
        jQuery(".trace-input-mob").keyup(function(e){
            var code = e.which; // recommended to use e.which, it's normalized across browsers
            if(code==13)e.preventDefault();
            if(code==32||code==13||code==188||code==186){
                var sel_po = jQuery(this).parent();
                track(sel_po,"2");
            }
        });
    
    
        jQuery('.frmExternalTrace .trace-button').on('click',function(e) {
            var sel_po = jQuery(this).parent();
            track(sel_po,"1");
    
        });
        jQuery('.frmExternalTrace .trace-button-mob').on('click',function(e) {
            var sel_po = jQuery(this).parent();
            track(sel_po,"2");
    
        });
    
    
        function track(sel,type) {
            var pro = "";
            var cat = "";
            var currentURL= window.location.toString();
            var base_url = window.location.origin;
            if(type == "1"){
                pro = jQuery("#pro",sel).val().replace(" ","");
            }else{
                pro = jQuery("#pron",sel).val().replace(" ","");
            }
            get_p_r(pro);
            
            if (currentURL.indexOf("portal")!==-1){
                if(jQuery("#catEn").length != 0) {
                    cat = 1;
                }else if(jQuery("#catFr").length != 0){
                    cat = 2;
                }else{
                    cat = 3;
                }
            }else{
                if (currentURL.indexOf("en")!==-1){
                    cat = 1;
                }else{
                    cat = 2;
                }
            }

            jQuery.when.apply($, arr).then(function(){
                if(type == "1" ){
                    
                    if (jQuery(sel).find('.trace-input').val() == "") {
                        // jQuery( '<div>The Polaris Tracking number must be entered to track a shipment.</div>' ).dialog({
                        //     modal: true,
                        //     title: "PLEASE NOTE",
                        //     buttons: [
                        //         {
                        //             text: "Ok",
                        //             icons: {
                        //                 primary: "ui-icon-circle-check"
                        //             },
                        //             click: function() {
                        //                 jQuery( this ).dialog( "close" );
                        //             }
                        //         }
                        //     ]
                        // }).prev(".ui-dialog-titlebar").css( "background","#FDF1F1");
                        jQuery('.trace-empty-modal').modal('show');
                    }
                    else {
                        console.log(pro);
                        //if (pro != jQuery("#pro",sel).val().replace(" ","")) {
                        //jQuery("#pro",sel).val(pro);
                        //}
                        var pro_type = pro.charAt(0);
                        var currentURL= window.location.toString();
                        if (pro_type == 'r' || pro_type == 'R') {
                            console.log(currentURL);
                            jQuery(sel).attr("action","trace-pgl.php");
                            //jQuery(sel).submit();
                            if(cat == 1 || cat == 3){
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/en/trace-pgl.php?pro=" + pro;
                            }else{
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/fr/trace-pgl.php?pro=" + pro;
                            }
                        }
                        else {
                            
                            jQuery(sel).attr("action","trace-polaris.php");
                            //jQuery(sel).submit();
                            if(cat == 1 || cat == 3){
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/en/trace-polaris.php?pro=" + pro;
                            }else{
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/fr/trace-polaris.php?pro=" + pro;
                            }
                        }
                    }
                }else{
                    
                    if (jQuery(sel).find('.trace-input-mob').val() == "") {
                        jQuery('.trace-empty-modal').modal('show');
                    }
                    else {
                        console.log(pro);
                        
                        var pro_type = pro.charAt(0);
                        if (pro_type == 'r' || pro_type == 'R') {
        
                            jQuery(sel).attr("action","trace-pgl.php");
                            //jQuery(sel).submit();
                            if(cat == 1 || cat == 3){
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/en//trace-pgl.php?pro=" + pro;
                            }else{
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/fr/trace-pgl.php?pro=" + pro;
                            }
                        }
                        else {
                            jQuery(sel).attr("action","trace-polaris.php");
                            //jQuery(sel).submit();
                            if(cat == 1 || cat == 3){
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/en//trace-polaris.php?pro=" + pro;
                            }else{
                                window.location.href = "https://dev.trypmserver.com/polaris2020/dist/fr/trace-polaris.php?pro=" + pro;
                            }

                        }
                    }
                }
            });
        }
    
    
        function get_p_r(num)
        {
            xhr = jQuery.ajax({
                dataType: 'json',
                url: 'https://api.polaristransport.com:1984/restgateway/services/ThinkPGL_Check_API/ThinkPGL_check',
                data: {'OrderNo': num }
            })
            .fail(function(xhr, textStatus, errorThrown) { console.log('Error: '+xhr.status+' - '+errorThrown); })
            .done(function(data) {
                console.log(data);
                pro = data["PGL_Trace"]["Trace"]["OrderNo"];
            });
            arr.push(xhr);
        }
    
        jQuery('.Home #ToolMenuTitle>span').removeClass('down').addClass('up');
        jQuery('#ToolMenuTitle,#ToolMenuIcon').hover(function() {
            jQuery('#ToolMenuTitle,#ToolMenuIcon').addClass('tool-menu-hover');
        },function() {
            jQuery('#ToolMenuTitle,#ToolMenuIcon').removeClass('tool-menu-hover');
        });
    
        jQuery('body').on('click','#ToolMenuTitle,#ToolMenuIcon',function() {
            jQuery('#ToolMenuContent').toggle();
            jQuery('#ToolMenuTitle>span').toggleClass('down').toggleClass('up');
        });
    
        jQuery('body').on('input','#frmName',function() {
            if(jQuery(this).val() == '') {
                jQuery(this).addClass('Error');
            } else {
                jQuery(this).removeClass('Error');
            }
        });
    
        jQuery('body').on('input','#frmEmail',function() {
            if(validateEmail(jQuery(this).val())) {
                jQuery(this).removeClass('Error');
                jQuery('.InvalidEmail').addClass('hidden');
            } else {
                jQuery(this).addClass('Error');
            }
        });
    
        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    
        jQuery('body').on('click','#SendNotif',function(e) {
            console.log('Inside Click');
            var NotifData = {
                'APIKey': 'MjIxMTUwMjUyOTcwNjU4MDA',
                'BILL_NUMBER': jQuery('.t_probill_no').html(),
                'NAME': jQuery('#frmName').val(),
                'EMAIL': jQuery('#frmEmail').val(),
                'COMPANY': jQuery('#frmPhone').val(),
                'STATUS': ''
            };
            if(!validateEmail(NotifData.EMAIL)) {
                jQuery('#frmEmail').addClass('Error');
                jQuery('.InvalidEmail').removeClass('hidden');
                if(NotifData.NAME == '') {
                    jQuery('#frmName').addClass('Error');
                } else {
                    jQuery('#frmName').removeClass('Error');
                }
                //	return false;
            }	else if (NotifData.NAME == '') {
                jQuery('#frmEmail').removeClass('Error');
                jQuery('.InvalidEmail').addClass('hidden');
                jQuery('#frmName').addClass('Error');
                //return false;
            } else {
                jQuery('#frmName').removeClass('Error');
                jQuery('#frmEmail').removeClass('Error');
                jQuery('.InvalidEmail').addClass('hidden');
            }
            console.log(NotifData);
            // console.log('Inside Click Event Handler Function');
            // console.log(jQuery.param(NotifData));
            if(!jQuery('input[type="text"].formStyle').hasClass('Error')) {
                jQuery.ajax({
                    dataType: 'json',
                    url: 'https://api.polaristransport.com:1984/restgateway/services/CustTraceWebAPI/CustTraceWeb',
                    data: jQuery.param(NotifData)
                })
                .fail(function(xhr, textStatus, errorThrown) {
                    console.log('Notif Update Failed!');
                })
                .done(function(data) {
                    console.log(data);
                    if(jQuery('.AjaxMessage').is(":visible")) {
                        jQuery('.AjaxMessage').fadeOut(400,function() {
                            if(data.SQL_CFG_1.SetCustomerTraceEmail.OSUCCESS.toLowerCase() == 'y') {
                                //jQuery('#Send').addClass('Success').prop('disabled',true);
                                jQuery('.AjaxMessage').removeClass('alert-warning').addClass('alert-success');
                                jQuery('#myModal').delay(2000).fadeOut();
                            } else {
                                jQuery('.AjaxMessage').removeClass('alert-success').addClass('alert-warning');
                            }
                            jQuery('.AjaxMessage').hide().html(data.SQL_CFG_1.SetCustomerTraceEmail.OMESSAGE).fadeIn();
                        });
                    } else {
                        if(data.SQL_CFG_1.SetCustomerTraceEmail.OSUCCESS.toLowerCase() == 'y') {
                            //jQuery('#Send').addClass('Success').prop('disabled',true);
                            jQuery('.AjaxMessage').removeClass('alert-warning').addClass('alert-success');
                            jQuery('#myModal').delay(2000).fadeOut();
                        } else {
                            jQuery('.AjaxMessage').removeClass('alert-success').addClass('alert-warning');
                        }
                        jQuery('.AjaxMessage').hide().html(data.SQL_CFG_1.SetCustomerTraceEmail.OMESSAGE).fadeIn();
                    }
                });
            } else {
                console.log('Inside the Else portion');
                jQuery('.AjaxMessage').fadeOut(400,function() {
                    jQuery('.AjaxMessage').html('Please complete the form before submitting.').removeClass('alert-success').addClass('alert-warning').fadeIn();
                });
            }
            return false;
        });
    });
    