//JAVASCRIPT REQUIRED FOR ENTIRE WEBSITE
let $form, $message, timeoutId;

function captchaSubmit(grecaptchaResponse) {
    // Considering jQuery.validate is being used as the validation mechanism
    if ($form.valid()) {
        $form.find('#g-recaptcha-response').length && $form.find('#g-recaptcha-response').remove();

        $.ajax({
            type: "POST",
            url: $form.attr("action"),
            data: $form.serialize() + "&g-recaptcha-response=" + grecaptchaResponse
        })
            .done(function (response) {
                // Display response message to the user | add success class to the message wrapper
                $message.slideUp(400, () => {
                    $message
                        .removeClass("error")
                        .addClass("success")
                        .html(response)
                        .slideDown();
                });
                // Hide the message after 7 seconds
                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(function () {
                    $message.slideUp(400, () => {
                        $message
                            .html("")
                            .removeClass("success");
                    });
                }, 7000);
                // Resetting reCaptcha (v2) if available
                grecaptcha && grecaptcha.reset();
                // Uncomment for Submiting Google Analytics

                // if (response.toLowerCase().indexOf('thank') > -1) {
                //     gtag('event', 'Form Submission - ' + $form[0].elements[$form[0].elements.length - 1].value, {
                //         'event_category': 'Form',
                //         'event_label': $form[0].elements[$form[0].elements.length - 1].value,
                //         'value': 1
                //     });
                // }
                // Reset the form input fields
                $form.trigger("reset");
            })
            .fail(function (error) {
                // Display error message to the user | add error class to the message wrapper
                console.log(error);
                $message.slideUp(400, () => {
                    $message
                        .removeClass("success")
                        .addClass("error-send-mail")
                        .text(response ? response : "Oops! An error occured")
                        .slideDown();
                });
            });
    } else {
        grecaptcha && grecaptcha.reset();
    }
}

jQuery(document).ready(function () {
    jQuery('#shippingTools-wrapper').css({ 'transform': 'translateX(calc(-100% + -1px)) !important' });
    jQuery('body').on('click', '.social-wrapper .icons', function () {
        transformVal = jQuery('.social-wrapper').css('transform');

    });

    jQuery('.icons.facebook').on('click', function () {
        jQuery('.icons').css('border-color', '#004c8c');
        jQuery('.icons.facebook').css('border-color', '#fcd234');
        jQuery('.linkedinposts').hide();
        jQuery('.twitterposts').hide();
        jQuery('.instagramposts').hide();
        if (jQuery('.facebookposts').css('display') != 'block') {
            jQuery('.social-wrapper').css('transform', 'none');
            jQuery('.facebookposts').show();
            // jQuery('.social-wrapper').css('margin-right','0');
        }
        else {
            jQuery('.social-wrapper').css('transform', 'translateX(calc(100% + 1px))');
            jQuery('.facebookposts').hide();
            jQuery('.icons').css('border-color', '#004c8c');

            // jQuery('.social-wrapper').css('margin-right','-300px');
        }
    })
    jQuery('.icons.twitter').on('click', function () {
        jQuery('.icons').css('border-color', '#004c8c');
        jQuery('.icons.twitter').css('border-color', '#fcd234');
        jQuery('.facebookposts').hide();
        jQuery('.linkedinposts').hide();
        jQuery('.instagramposts').hide();
        if (jQuery('.twitterposts').css('display') != 'block') {
            jQuery('.social-wrapper').css('transform', 'none');
            jQuery('.twitterposts').show();
            // jQuery('.social-wrapper').css('margin-right','0');
        }
        else {
            jQuery('.social-wrapper').css('transform', 'translateX(calc(100% + 1px))');
            jQuery('.twitterposts').hide();
            jQuery('.icons').css('border-color', '#004c8c');

            // jQuery('.social-wrapper').css('margin-right','-300px');
        }
    })
    jQuery('.icons.linkedin').on('click', function () {
        jQuery('.icons').css('border-color', '#004c8c');
        jQuery('.icons.linkedin').css('border-color', '#fcd234');
        jQuery('.facebookposts').hide();
        jQuery('.twitterposts').hide();
        jQuery('.instagramposts').hide();
        if (jQuery('.linkedinposts').css('display') != 'block') {
            jQuery('.social-wrapper').css('transform', 'none');
            jQuery('.linkedinposts').show();
            // jQuery('.social-wrapper').css('margin-right','0');
        }
        else {
            jQuery('.social-wrapper').css('transform', 'translateX(calc(100% + 1px))');
            jQuery('.linkedinposts').hide();
            jQuery('.icons').css('border-color', '#004c8c');

            // jQuery('.social-wrapper').css('margin-right','-300px');
        }
    })
    jQuery('.icons.instagram').on('click', function () {
        jQuery('.icons').css('border-color', '#004c8c');
        jQuery('.icons.instagram').css('border-color', '#fcd234');
        jQuery('.facebookposts').hide();
        jQuery('.twitterposts').hide();
        jQuery('.linkedinposts').hide();
        if (jQuery('.instagramposts').css('display') != 'block') {
            jQuery('.social-wrapper').css('transform', 'none');
            jQuery('.instagramposts').show();
            // jQuery('.social-wrapper').css('margin-right','0');
        }
        else {
            jQuery('.social-wrapper').css('transform', 'translateX(calc(100% + 1px))');
            jQuery('.instagramposts').hide();
            jQuery('.icons').css('border-color', '#004c8c');

            // jQuery('.social-wrapper').css('margin-right','-300px');
        }
    })






    if (!jQuery('.dropdown-z').hasClass('shows')) {
        jQuery(".dropdown-z").click(function () {
            jQuery("#submenu-dropdown-item1,#dropdownHover").addClass("shows");
            jQuery(".dropdown-z").css("color", "#004c8c");
        });
    }
    if (!jQuery('.dropdown-y').hasClass('shows')) {
        jQuery(".dropdown-y").click(function () {
            jQuery("#submenu-dropdown-item0,#dropdownHover0").addClass("shows");
            jQuery(".dropdown-y").css("color", "#004c8c");
        });
    }
    jQuery(".dropdown-toggle").mouseover(function () {
        jQuery("#dropdownHover0").removeClass("dropdown-c");
    });
    if (!jQuery('#dropdownHover0').hasClass('dropdown-c')) {
        jQuery(".dropdown-y").mouseover(function () {
            jQuery("#dropdownHover0").addClass("dropdown-c");
        });
    }
    if (jQuery('#dropdownHover0').hasClass('dropdown-c')) {
        jQuery(".dropdown-x0").mouseover(function () {
            jQuery("#dropdownHover0").removeClass("dropdown-c");
        });
        jQuery(".dropdown-x0").mouseleave(function () {
            jQuery("#dropdownHover0").addClass("dropdown-c");
        });
    }



    jQuery(document).on("submit", 'form', e => {
        e.preventDefault();
        $form = $(e.target);
        $message = $form.find(".form-messages");
        grecaptcha.execute();
        return false;
    });

    jQuery('.crossborder-wrapper .row div').on('mouseover', function () {
        jQuery(this).find('h2').hide();
        jQuery(this).find('div').show();
        // jQuery(this).css('background-color', '#004c8c');

    })
    jQuery('.crossborder-wrapper .row div').on('mouseout', function () {
        jQuery(this).find('h2').show();
        jQuery(this).find('div').hide();
        // if(($(this).index() + 1) % 2 == 1){
        // $(this).css('background-color', '#004c8acc');
        // }
        // else{
        // $(this).css('background-color', 'transparent');
        // }


    });
    var transformVal;
    // setTimeout(function() {
    // $('#shippingTools-wrapper').css({'animation-name': 'slideinright'});

    // },2000);
    jQuery('body').on('click', '.shippingTools-button', function () {

        transformVal = jQuery('#shippingTools-wrapper').css('transform');
        if (jQuery(window).width() < 576) {
            if (transformVal == 'none') {
                jQuery('#shippingTools-wrapper').css('transform', 'translateY(100%)').find('.mc-closeModal').css('visibility', 'hidden');
            } else {
                jQuery('#shippingTools-wrapper').css('transform', 'none').find('.mc-closeModal').css('visibility', 'visible');
            }
        } else {
            if (transformVal == 'none') {
                // jQuery('#shippingTools-wrapper').removeClass('showwrapper');

                jQuery('#shippingTools-wrapper').css({ 'transform': 'translateX(calc(-100% + -1px))' });



            } else {
                jQuery('#shippingTools-wrapper').css('transform', 'none');
            }
        }
    });

    jQuery('#shippingTools-wrapper').css({ 'transform': 'translateX(calc(-100% + -1px)) !important' });

    jQuery("input[name='frmMc_CVORNumber']").change(function() {
        if (jQuery("input[name='frmMc_CVORNumber']:checked").val() === "No" || jQuery("input[name='frmMc_CVORNumber']:checked").val() === "N/A"){
            jQuery('textarea[name="Mc_CVORNumberYes"]').removeClass('required');
            jQuery('textarea[name="Mc_CVORNumberYes"]').removeClass('error');
            jQuery('label[for="Mc_CVORNumberYes"]').remove();
            //jQuery('#wsib-no').addClass('remove-asterick');
            if (jQuery('label[for="Mc_CVORNumberYes"]').length > 0) {
                jQuery('label[for="Mc_CVORNumberYes"]').remove();
            }
        }
        else{
            // jQuery('input[name="Mc_CVORNumberYes"]').attr("required", true);
            jQuery('textarea[name="Mc_CVORNumberYes"]').addClass('required');
            
            jQuery('textarea[name="Mc_CVORNumberYes"]').addClass('error');
            jQuery('#wsib-no').removeClass('remove-asterick');
            if (jQuery('label[for="Mc_CVORNumberYes"]').length == 0) {
                jQuery('textarea[name="Mc_CVORNumberYes"]').after('<label for="Mc_CVORNumberYes" class="error">This field is required.</label>')
            }
        }
    });
    jQuery("input[name='frm3PL_Transportation']").on("change", function(){
        
        if (jQuery("input[name='frm3PL_Transportation']:checked").val() === "Yes") {
            // if(jQuery(this).css('display')=='none'){
            jQuery(".MC_Surety").css('display','block');
            jQuery(this).addClass('required');
            jQuery("input[name='frmSuretyBond']").addClass('required');
            jQuery("input[name='frmMc_CVORNumber']").addClass('required');
        }
        else{
            jQuery(".MC_Surety").css('display','none');
            jQuery(this).removeClass('required');
            jQuery("input[name='frmSuretyBond']").prop('checked', false);
            jQuery("input[name='frmSuretyBond']").removeClass('required');
            jQuery('textarea[name="SuretyBondYes"]').val("");
            jQuery('textarea[name="SuretyBondYes"]').removeClass('required');
            jQuery('textarea[name="SuretyBondYes"]').removeClass('error');
            jQuery('label[for="SuretyBondYes"]').remove();
            //jQuery('#wsib-no').addClass('remove-asterick');
            if (jQuery('label[for="SuretyBondYes"]').length > 0) {
                jQuery('label[for="SuretyBondYes"]').remove();
            }
            jQuery("input[name='frmMc_CVORNumber']").prop('checked', false);
            jQuery("input[name='frmMc_CVORNumber']").removeClass('required');
            jQuery('textarea[name="Mc_CVORNumberYes"]').val("");
            jQuery('textarea[name="Mc_CVORNumberYes"]').removeClass('required');
            jQuery('textarea[name="Mc_CVORNumberYes"]').removeClass('error');
            jQuery('label[for="Mc_CVORNumberYes"]').remove();
            //jQuery('#wsib-no').addClass('remove-asterick');
            if (jQuery('label[for="Mc_CVORNumberYes"]').length > 0) {
                jQuery('label[for="Mc_CVORNumberYes"]').remove();
            }
        }
        
    })
       jQuery("input[name='frmSuretyBond']").change(function() {
        if (jQuery("input[name='frmSuretyBond']:checked").val() === "No" || jQuery("input[name='frmSuretyBond']:checked").val() === "N/A"){
            jQuery('textarea[name="SuretyBondYes"]').removeClass('required');
            jQuery('textarea[name="SuretyBondYes"]').removeClass('error');
            jQuery('label[for="SuretyBondYes"]').remove();
            //jQuery('#wsib-no').addClass('remove-asterick');
            if (jQuery('label[for="SuretyBondYes"]').length > 0) {
                jQuery('label[for="SuretyBondYes"]').remove();
            }
        }
        else{
            // jQuery('input[name="SuretyBondYes"]').attr("required", true);
            jQuery('textarea[name="SuretyBondYes"]').addClass('required');
            
            jQuery('textarea[name="SuretyBondYes"]').addClass('error');
            jQuery('#wsib-no').removeClass('remove-asterick');
            if (jQuery('label[for="SuretyBondYes"]').length == 0) {
                jQuery('textarea[name="SuretyBondYes"]').after('<label for="SuretyBondYes" class="error">This field is required.</label>')
            }
        }
    
})

});







if (window.history.replaceState) {
    window.history.replaceState(null, null, window.location.href);
}

jQuery(function () {

    var availableTags = [
        "AB - Alberta",
        "BC - British Columbia",
        "MB - Manitoba",
        "NB - New Brunswick",
        "NL - Newfoundland and Labrador",
        "NS - Nova Scotia",
        "NT - Northwest Territories",
        "NU - Nunavut",
        "ON - Ontario",
        "PE - Prince Edward Island",
        "PQ - Quebec",
        "QC - Quebec",
        "SK - Saskatchewan",
        "YK - Yukon",
        "AK - Alaska",
        "AL - Alabama",
        "AR - Arkansas",
        "AZ - Arizona",
        "CA - California",
        "CO - Colorado",
        "CT - Connecticut",
        "DC - District of Columbia",
        "DE - Delaware",
        "FL - Florida",
        "GA - Georgia",
        "HI - Hawaii",
        "IA - Iowa",
        "ID - Idaho",
        "IL - Illinois",
        "IN - Indiana",
        "KS - Kansas",
        "KY - Kentucky",
        "LA - Louisiana",
        "MA - Massachusetts",
        "MD - Maryland",
        "ME - Maine",
        "MI - Michigan",
        "MN - Minnesota",
        "MO - Missouri",
        "MS - Mississippi",
        "MT - Montana",
        "NC - North Carolina",
        "ND - North Dakota",
        "NE - Nebraska",
        "NH - New Hampshire",
        "NJ - New Jersey",
        "NM - New Mexico",
        "NV - Nevada",
        "NY - New York",
        "OH - Ohio",
        "OK - Oklahoma",
        "OR - Oregon",
        "PA - Pennsylvania",
        "PR - Puerto Rico",
        "RI - Rhode Island",
        "SC - South Carolina",
        "SD - South Dakota",
        "TN - Tennessee",
        "TX - Texas",
        "UT - Utah",
        "VA - Virginia",
        "VT - Vermont",
        "WA - Washington",
        "WI - Wisconsin",
        "WV - West Virginia",
        "WY - Wyoming"
    ];
    // jQuery( "#frmProvinceState,#frmBusinessProvinceState,#frmBankingProvinceState,#RateRequestForm #frmFromProvinceState,#RateRequestForm #frmToProvinceState,#TradeshowExhibitRateRequestForm #frmProvinceState1,#TradeshowExhibitRateRequestForm #frmProvinceState2,#ExpeditedQuoteForm #frmFromProvinceState,#ExpeditedQuoteForm #frmToProvinceState,#CreditApplicationForm #frmAppProvinceState" ).autocomplete({
    //   source: function(req, responseFn) {
    //     var re = $.ui.autocomplete.escapeRegex(req.term);
    //     var matcher = new RegExp( "^" + re, "i" );
    //     var a = $.grep( availableTags, function(item,index){
    //         return matcher.test(item);
    //     });
    //     responseFn( a );
    //     }
    // });
});
    // $( "#RateRequestForm #frmFromProvinceState" ).autocomplete({
    //     source: availableTags
    //   });

    // document.getElementById("dcsmt-4-facebook").innerText('heeloo');
    // $('#social-tabs').on('click', '.dcsmt-slider .tab-facebook .tab-inner #dcsmt-4-facebook',function(){
    //     console.log('hello');
    //     var ifrm = document.createElement("iframe");
    //         ifrm.setAttribute(
    //           "src",
    //           "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPolarisTransport&tabs=timeline&width=300&height=630&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
    //         );
    //         ifrm.style.width = "300px";
    //         ifrm.style.height = "630px";
    //         document.getElementById("dcsmt-4-facebook").appendChild(ifrm);
    // })

//   });
//   _$( window ).on( "load", function() {
//     var s;
// s= $('#social-tabs .dcsmt-slider');
// console.log(s);
// _$('.dcsmt-facebook').on('click',function(){
//          console.log('hello');
// });
// var ifrm = document.createElement("iframe");
//             ifrm.setAttribute(
//               "src",
//               "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPolarisTransport&tabs=timeline&width=300&height=630&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
//             );
//             ifrm.style.width = "300px";
//             ifrm.style.height = "630px";
//             document.getElementById("dcsmt-4-facebook").appendChild(ifrm);
// });

function testVowels(e) {
    const regex = new RegExp('^[rR].*', 'i');
    let inputVal = $(e.target).val();
    let regexOutput = regex.test(inputVal);
    
    // If is true
    if(regexOutput) {
        $('.tooltiptext').css("visibility", "visible");
    } else {
        $('.tooltiptext').css("visibility", "hidden");
    }
}
$('#pro').bind('input propertychange', function() {
    const regex = new RegExp('^[rR].*', 'i');
    let inputVal = $(this).val();
    let regexOutput = regex.test(inputVal);
    
    // If is true
    if(regexOutput) {
        $('.tooltiptext').css("visibility", "visible");
    } else {
        $('.tooltiptext').css("visibility", "hidden");
    }
})

